/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import {
  useGetCutoffTime,
  useGetMachShipCarriers,
  useGetStarshipITCarriers,
} from 'framework/api/methods'

import { DatePicker, SimpleSelect, ToggleSwitch } from 'components/atoms'
import { Button } from 'components/atoms/Button'

export interface ConsignedWithoutQuoteModalProps {
  className?: string | undefined
  shipments?: any
  handleConsignWithoutQuoteConfirmClick?: any
  isActive?: boolean | undefined
  isAPXDisable?: boolean
  isBulkConsignment?: boolean | undefined
  isCustomStyles?: boolean | undefined
  notify?: any
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  selectedShipmentId?: any
  useGetCarrierProducts?: any
  selectedOrderDetails?: any
  warehouseTimezone: string | undefined | null
}

export const ConsignedWithoutQuoteModal: FC<
  ConsignedWithoutQuoteModalProps
> = ({
  className,
  shipments,
  handleConsignWithoutQuoteConfirmClick,
  isActive,
  isAPXDisable,
  isBulkConsignment,
  isCustomStyles,
  notify,
  onCloseClick,
  onOverlayClick,
  selectedShipmentId,
  useGetCarrierProducts,
  selectedOrderDetails,
  warehouseTimezone,
}: ConsignedWithoutQuoteModalProps) => {
  const [selectedCourierType, setSelectedCourierType] = useState<any>(null)
  const [selectedServiceType, setSelectedServiceType] = useState<any>(null)

  const [selectedStarShipItCourierType, setSelectedStarShipItCourierType] =
    useState<any>(null)

  const [selectedMachShipCourierType, setSelectedMachShipCourierType] =
    useState<any>(null)

  /** dse extra fields */
  const [showDSEExtraFields, setShowDSEExtraFields] = useState(false)
  const [ATLValue, setATLValue] = useState(false)
  const [pickUpDate, setPickUpDate] = useState<any>(null)
  const [cutoffTime, setCutoffTime] = useState<any>()
  const [personToLift, setPersonToLift] = useState<number>(0)

  /** Service Type Arrays */
  const [serviceTypes, setServiceTypes] = useState([])
  const [ausPostTypes, setAusPostTypes] = useState([])
  const [ausPostInternationalTypes, setAusPostInternationalTypes] = useState([])
  const [directExpressTypes, setDirectExpressTypes] = useState([])
  const [dseTypes, setDseTypes] = useState([])
  const [starshipitTypes, setStarshipitTypes] = useState([])
  const [machShipTypes, setMachShipTypes] = useState([])

  const [starshipitCourierList, setStarshipitCourierList] = useState<any>([])
  const [macShipCourierList, setMacShipCourierList] = useState<any>([])

  const { shippingSummeryWarehouseCode }: any = useSelector<any>(
    (state) => state.order,
  )
  const [containsPOBoxOrParcelLocker, setContainsPOBoxOrParcelLocker] =
    useState(false)

  /** API Call for get cutoff time using react-query */
  const { mutate: getCutOffTimeMutate, isLoading: getCutOffTimeIsLoading } =
    useGetCutoffTime()

  /** Process the get cutoff time */
  async function getCutoffTime() {
    getCutOffTimeMutate(
      {
        warehouseCode:
          shippingSummeryWarehouseCode || selectedOrderDetails?.warehouse?.code,
        courierCode: selectedCourierType?.value,
        subCourierCode:
          selectedCourierType?.value === 'STARSHIPIT'
            ? selectedStarShipItCourierType?.value
            : null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData?.cutoffTime?.status
            ? setCutoffTime(successData?.cutoffTime?.cutofffTime)
            : setCutoffTime(null)
        },
        onError: ({ response: { data: errData } }: any) => {
          setCutoffTime(null)
        },
      },
    )
  }

  useEffect(() => {
    if (
      selectedCourierType?.value !== 'STARSHIPIT' ||
      selectedCourierType?.value !== 'MACHSHIP'
    ) {
      selectedCourierType?.value && selectedOrderDetails && getCutoffTime()
    } else if (selectedCourierType?.value === 'STARSHIPIT') {
      selectedCourierType?.value &&
        selectedStarShipItCourierType?.value &&
        selectedOrderDetails &&
        getCutoffTime()
    } else if (selectedCourierType?.value === 'MACHSHIP') {
      selectedCourierType?.value &&
        selectedMachShipCourierType?.value &&
        selectedOrderDetails &&
        getCutoffTime()
    }
  }, [
    selectedCourierType?.value,
    selectedOrderDetails,
    selectedStarShipItCourierType?.value,
    selectedMachShipCourierType?.value,
  ])

  /** set pick up date */
  useEffect(() => {
    const [time, modifier]: any = cutoffTime?.split(' ') || []

    let hours = time?.split(':')[0]
    const minutes = time?.split(':')[1]

    if (hours === '12') {
      hours = '00'
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }

    const formattedTime = `${hours}:${minutes}`

    /** compare formattedTime and real time and return is greater than or less that  */
    const isCutoffTimePassed = () => {
      const [hours, minutes] = formattedTime.split(':')
      const now = new Date()
      const cutoff = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10),
      )
      // Define the desired UTC offset string (e.g., 'UTC+10:00')
      const offsetString = warehouseTimezone || ''
      const offsetRegex = /UTC([+-]\d{2}):(\d{2})/
      const match = offsetString?.match(offsetRegex)
      if (match) {
        const desiredUTCOffsetHours = parseInt(match[1])
        const desiredUTCOffsetMinutes = parseInt(match[2])
        // Create a new Date object representing the current UTC time
        const currentUTCTimeMillis = Date.now()
        const currentUtcOffsetMillis =
          new Date().getTimezoneOffset() * 60 * 1000
        // Calculate the offset in milliseconds
        const offsetMilliseconds =
          (desiredUTCOffsetHours * 60 + desiredUTCOffsetMinutes) * 60 * 1000
        // Calculate the time for the desired UTC offset by adding the offset in milliseconds
        const currentDateTimeWithOffset = new Date(
          currentUTCTimeMillis + currentUtcOffsetMillis + offsetMilliseconds,
        )
        return currentDateTimeWithOffset > cutoff
      }
      return true
    }

    /** set default pickup date */
    const defaultPickDate = () => {
      return defaultDate
    }

    /** set default date */
    const defaultDate = new Date()
    if (cutoffTime && isCutoffTimePassed()) {
      if (defaultDate.getDay() === 5) {
        defaultDate.setDate(defaultDate.getDate() + 3)
      } else if (defaultDate.getDay() === 6) {
        defaultDate.setDate(defaultDate.getDate() + 2)
      } else {
        defaultDate.setDate(defaultDate.getDate() + 1)
      }
    } else {
      defaultDate.setDate(defaultDate.getDate())
    }

    setPickUpDate(defaultPickDate())
  }, [cutoffTime])
  const [alliedExpressTypes, setAlliedExpressTypes] = useState([])

  /** Function On-Success Get Currier Products */
  const getCarrierProductsIsOnSuccess = (data: any) => {
    /** Manipulating Auspost Data Array */
    const ausPost = data.auspost.map((item: any) => {
      return {
        label: item.productName,
        value: item.productCode,
      }
    })
    setAusPostTypes(ausPost)

    /** Manipulating Auspost International Data Array */
    const ausPostInternational = data?.auspostinternational?.map(
      (item: any) => {
        return {
          label: item.productName,
          value: item.productCode,
        }
      },
    )
    setAusPostInternationalTypes(ausPostInternational)

    /** Manipulating Direct Express Data Array */
    const directExpress = data.direct.map((item: any) => {
      return {
        label: item.productName,
        value: item.productCode,
      }
    })
    setDirectExpressTypes(directExpress)

    /** Manipulating DSE Data Array */
    const dse = data?.dse.map((item: any) => {
      return {
        label: item.productName,
        value: item.productCode,
      }
    })
    setDseTypes(dse)

    /** Manipulating Starshipit Data Array */
    const starshipit = data?.starshipit.map((item: any) => {
      return {
        label: item.productName,
        value: item.productCode,
        carrierCode: item.carrierCode,
      }
    })
    setStarshipitTypes(starshipit)

    /** Manipulating MachShip Data Array */
    const machship = data?.machship.map((item: any) => {
      return {
        label: item.productCode,
        value: item.id,
        carrierCode: item.productName,
      }
    })
    setMachShipTypes(machship)

    const alliedExpress = data.alliedExpress.map((item: any) => {
      return {
        label: item.productName,
        value: item.productCode,
      }
    })
    setAlliedExpressTypes(alliedExpress)
  }

  /** Function On-Error Get Currier Products*/
  const getCarrierProductsIsOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Currier Products */
  const {
    isLoading: GetCarrierProductsIsLoading,
    refetch: callGetCarrierProducts,
  } = useGetCarrierProducts(
    {},
    getCarrierProductsIsOnSuccess,
    getCarrierProductsIsOnError,
  )

  useEffect(() => {
    callGetCarrierProducts()
  }, [])

  const CourierTypes = [
    {
      value: 'ALLIED',
      label: 'AlliedExpress',
    },
    {
      value: 'AUSPOST',
      label: 'Australia Post',
    },
    {
      value: 'DIRECT',
      label: 'Direct Express',
    },
    {
      value: 'DSE',
      label: 'DSE',
    },
    {
      value: 'MACHSHIP',
      label: 'MachShip',
    },
    {
      value: 'STARSHIPIT',
      label: 'Starshipit',
    },
    {
      value: 'AUSPOST_INTERNATIONAL',
      label: 'Australia Post International',
    },
  ]

  const CourierTypesWithoutAPX = [
    {
      value: 'ALLIED',
      label: 'AlliedExpress',
    },
    {
      value: 'AUSPOST',
      label: 'Australia Post',
    },
    {
      value: 'DIRECT',
      label: 'Direct Express',
    },
    {
      value: 'DSE',
      label: 'DSE',
    },
    {
      value: 'STARSHIPIT',
      label: 'Starshipit',
    },
    {
      value: 'MACHSHIP',
      label: 'MachShip',
    },
  ]

  /** Handling Service type according to selected courier service */
  const onChangeCourierType = (e: any) => {
    setSelectedCourierType(e)
    setShowDSEExtraFields(false)
    const courier = e.value
    setSelectedServiceType(null)
    setSelectedStarShipItCourierType(null)

    switch (courier) {
      case 'AUSPOST':
        setServiceTypes(ausPostTypes)
        break
      case 'AUSPOST_INTERNATIONAL':
        setServiceTypes(ausPostInternationalTypes)
        break
      case 'DIRECT':
        setServiceTypes(directExpressTypes)
        break
      case 'DSE':
        setServiceTypes(dseTypes)
        setShowDSEExtraFields(true)
        break
      case 'STARSHIPIT':
        setServiceTypes([])
        getStarshipITCarriers()
        break
      case 'MACHSHIP':
        setServiceTypes([])
        getMachShipCarriers()
        break
      case 'ALLIED':
        setServiceTypes(alliedExpressTypes)
        break
      default:
        setServiceTypes([])
        break
    }
  }

  /** Handling Service type according to selected courier service in starshipit */
  const onChangeStarShipItCourierType = (e: any) => {
    setSelectedServiceType(null)
    setSelectedStarShipItCourierType(e)
    const courier = e.value

    const starShipItServices = starshipitTypes?.filter(
      (item: any) => item.carrierCode === courier,
    )

    setServiceTypes(starShipItServices)
  }

  /** Handling Service type according to selected courier service in mach ship */
  const onChangeMachShipCourierType = (e: any) => {
    setSelectedServiceType(null)
    setSelectedMachShipCourierType(e)
    const courier = e.label

    const machShipServices = machShipTypes?.filter(
      (item: any) => item.carrierCode === courier,
    )

    setServiceTypes(machShipServices)
  }

  /** Handling Service types*/
  const onChangeServiceType = (e: any) => {
    setSelectedServiceType(e)
  }

  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  /** Handling on change person to lift  */
  const onChangePersonToLift = (event: any) => {
    event.target.value && setPersonToLift(parseInt(event.target.value))
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const handleConfirmClick = (event: any) => {
    event.stopPropagation()
    handleConsignWithoutQuoteConfirmClick(
      selectedShipmentId,
      selectedCourierType.value || '',
      selectedCourierType?.value === 'MACHSHIP'
        ? selectedServiceType.label
        : selectedServiceType.value || '',
      ATLValue,
      personToLift,
      pickUpDate,
      selectedStarShipItCourierType?.value || null,
      selectedMachShipCourierType?.label || null,
    )
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-[10] top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  useEffect(() => {
    if (
      isActive &&
      shipments?.length > 0 &&
      shipments[0]?.defaultCarrierCode &&
      shipments[0]?.defaultCarrierName
    ) {
      if (shipments[0]?.defaultCarrierCode === 'AUSPOST') {
        setSelectedCourierType({
          value: 'AUSPOST',
          label: 'Australia Post',
        })
      } else if (shipments[0]?.defaultCarrierCode === 'AUSPOST_INTERNATIONAL') {
        setSelectedCourierType({
          value: 'AUSPOST_INTERNATIONAL',
          label: 'Australia Post International',
        })
      } else if (shipments[0]?.defaultCarrierCode === 'DIRECT') {
        setSelectedCourierType({
          value: 'DIRECT',
          label: 'Direct Express',
        })
      } else if (shipments[0]?.defaultCarrierCode === 'DSE') {
        setSelectedCourierType({
          value: 'DSE',
          label: 'DSE',
        })
        setShowDSEExtraFields(true)
      } else if (shipments[0]?.defaultCarrierCode === 'STARSHIPIT') {
        setSelectedCourierType({
          value: 'STARSHIPIT',
          label: 'Starshipit',
        })
        getStarshipITCarriers()
      } else if (shipments[0]?.defaultCarrierCode === 'MACHSHIP') {
        setSelectedCourierType({
          value: 'MACHSHIP',
          label: 'MachShip',
        })
      } else if (shipments[0]?.defaultCarrierCode === 'ALLIED') {
        setSelectedCourierType({
          value: 'ALLIED',
          label: 'AlliedExpress',
        })
      } else {
        setServiceTypes([])
      }
    }
  }, [isActive])

  useEffect(() => {
    if (
      isActive &&
      shipments?.length > 0 &&
      shipments[0]?.defaultCarrierCode &&
      shipments[0]?.defaultCarrierName
    ) {
      if (shipments[0]?.defaultCarrierCode === 'AUSPOST') {
        setServiceTypes(ausPostTypes)
      } else if (shipments[0]?.defaultCarrierCode === 'AUSPOST_INTERNATIONAL') {
        setServiceTypes(ausPostInternationalTypes)
      } else if (shipments[0]?.defaultCarrierCode === 'DIRECT') {
        setServiceTypes(directExpressTypes)
      } else if (shipments[0]?.defaultCarrierCode === 'DSE') {
        setServiceTypes(dseTypes)
      } else if (shipments[0]?.defaultCarrierCode === 'STARSHIPIT') {
        setServiceTypes([])
      } else if (shipments[0]?.defaultCarrierCode === 'MACHSHIP') {
        setServiceTypes([])
      } else {
        setServiceTypes([])
      }
    }
  }, [
    shipments?.value,
    ausPostTypes,
    ausPostInternationalTypes,
    directExpressTypes,
    dseTypes,
    starshipitTypes,
    machShipTypes,
  ])

  const getStarshipITCarriersOnSuccess = (data: any) => {
    setStarshipitCourierList(data?.starshipitCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getStarshipITCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of starshipit */
  const { refetch: getStarshipITCarriers } = useGetStarshipITCarriers(
    {},
    getStarshipITCarriersOnSuccess,
    getStarshipITCarriersOnError,
  )

  const getMachShipCarriersOnSuccess = (data: any) => {
    setMacShipCourierList(data?.machShipCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getMachShipCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of machship */
  const { refetch: getMachShipCarriers } = useGetMachShipCarriers(
    {},
    getMachShipCarriersOnSuccess,
    getMachShipCarriersOnError,
  )

  const containsKeyword = (value: any, keyword: any) => {
    return value?.toLowerCase()?.includes(keyword?.toLowerCase())
  }

  useEffect(() => {
    const address: any = selectedOrderDetails?.partnerOrder?.shippingAddress

    if (address.countryCode == 'AU') {
      for (const key in address) {
        // eslint-disable-next-line no-prototype-builtins
        // eslint-disable-next-line no-prototype-builtins
        if (address?.hasOwnProperty(key)) {
          const value = address[key]
          if (
            value?.toLowerCase()?.includes('po box') ||
            value?.toLowerCase()?.includes('parcel locker')
          ) {
            setContainsPOBoxOrParcelLocker(true)
            setSelectedCourierType({
              value: 'AUSPOST',
              label: 'Australia Post',
            })
            break
          }
        }
      }
    }
  }, [selectedOrderDetails])

  useEffect(() => {
    if (
      selectedOrderDetails?.partnerOrder?.orderType === 'ON' &&
      selectedOrderDetails?.partnerOrder?.partner?.code === '42167'
    ) {
      const countryCode =
        selectedOrderDetails?.partnerOrder?.shippingAddress?.countryCode
      if (countryCode === 'AU') {
        setSelectedCourierType({
          value: 'AUSPOST',
          label: 'Australia Post',
        })
        setServiceTypes(ausPostTypes)
      } else if (countryCode !== 'AU') {
        setSelectedCourierType({
          value: 'AUSPOST_INTERNATIONAL',
          label: 'Australia Post International',
        })
        setServiceTypes(ausPostInternationalTypes)
      }
    }
  }, [selectedOrderDetails, ausPostTypes, ausPostInternationalTypes])

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[502px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Consign Without a Quote</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>

        {/* Modal Body  */}
        <div className='flex flex-col w-full pt-6 pl-4 pr-[18px]'>
          <div className='flex w-full bg-N-100 mb-8'>
            <div className='flex justify-center items-center pl-[18px] pr-[14px]'>
              <i className='ri-error-warning-fill text-[#0066FF] text-xl'></i>
            </div>

            <div className='flex flex-col justify-center items-start pr-4 py-3 text-base text-N-700'>
              <span className='font-semibold '>Hey there!</span>
              <span>
                You are about to consign this order without getting a quote from
                a courier. So, the courier charges you see here might not be the
                latest or correct ones.
              </span>
            </div>
          </div>

          <div className='select-courier-drop-down flex flex-col pb-8'>
            <div className='flex'>
              <span className='text-xs font-medium text-N-700 pb-2'>
                Select Carrier
              </span>
              <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
            </div>

            <SimpleSelect
              onChange={onChangeCourierType}
              options={
                selectedOrderDetails?.partnerOrder?.shippingAddress
                  ?.countryCode == 'AU'
                  ? CourierTypesWithoutAPX
                  : CourierTypes
              }
              value={selectedCourierType}
              disabled={containsPOBoxOrParcelLocker && !isBulkConsignment}
            />
          </div>

          {selectedCourierType?.value === 'STARSHIPIT' && (
            <div className='select-courier-drop-down flex flex-col pb-8'>
              <div className='flex'>
                <span className='text-xs font-medium text-N-700 pb-2'>
                  Select Starshipit Carrier
                </span>
                <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
              </div>

              <SimpleSelect
                onChange={onChangeStarShipItCourierType}
                options={starshipitCourierList}
                value={selectedStarShipItCourierType}
              />
            </div>
          )}

          {selectedCourierType?.value === 'MACHSHIP' && (
            <div className='select-courier-drop-down flex flex-col pb-8'>
              <div className='flex'>
                <span className='text-xs font-medium text-N-700 pb-2'>
                  Select MachShip Carrier
                </span>
                <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
              </div>

              <SimpleSelect
                onChange={onChangeMachShipCourierType}
                options={macShipCourierList}
                value={selectedMachShipCourierType}
              />
            </div>
          )}

          <div className='select-service-type-drop-down flex flex-col pb-8'>
            <div className='flex'>
              <span className='text-xs font-medium text-N-700 pb-2'>
                Select Service Type
              </span>
              <span className='text-R-500 ml-[2px] leading-[16px]'>*</span>
            </div>

            <SimpleSelect
              onChange={onChangeServiceType}
              options={
                containsPOBoxOrParcelLocker && !isBulkConsignment
                  ? ausPostTypes
                  : serviceTypes
              }
              value={selectedServiceType}
            />
          </div>

          <div className='select-service-type-drop-down flex flex-col'>
            <span className='text-xs font-medium text-N-700 pb-2'>
              Pick Up Date
            </span>
            <DatePicker
              enabled={true}
              onChange={(e) => setPickUpDate(e.target.value)}
              value={pickUpDate}
              allowEdit={false}
              minDate={new Date()}
              disableWeekends={true}
            />
          </div>

          {showDSEExtraFields && (
            <div className='select-service-type-drop-down flex flex-col mt-8'>
              <span className='text-xs font-medium text-N-700 pb-2'>
                Person To Lift
              </span>

              <input
                type='number'
                min={0}
                value={personToLift}
                className='px-4 py-2 border border-N-200 focus:border-N-300  rounded outline-none w-60'
                onChange={onChangePersonToLift}
              />
            </div>
          )}

          {!isBulkConsignment && (
            <ToggleSwitch
              text='ATL'
              setValue={ATLValue}
              className='mt-8'
              onToggleClick={() => {
                setATLValue(!ATLValue)
              }}
            />
          )}
        </div>

        {/* Modal Footer  */}
        <div className='w-full border-t border-t-N-200 mt-9 px-5 py-4'>
          <Button
            onClick={(e: any) => {
              handleConfirmClick(e)
            }}
            disabled={
              (shipments[0]?.defaultCarrierCode === 'AUSPOST_INTERNATIONAL' &&
                isAPXDisable) ||
              selectedCourierType === null ||
              selectedServiceType === null ||
              getCutOffTimeIsLoading
                ? true
                : false
            }
            isLoading={GetCarrierProductsIsLoading}>
            Consign
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

ConsignedWithoutQuoteModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  isAPXDisable: false,
  isBulkConsignment: false,
  onCloseClick: undefined,
  onOverlayClick: undefined,
  selectedOrderDetails: undefined,
}

export default ConsignedWithoutQuoteModal
